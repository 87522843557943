// ==============================
// Custom style
// 自定义样式
// ==============================


@media only screen and (max-width: 960px) {
  .promobanner {
      display: none;
  }
}

.promobanner{
    left: 0;max-width: 20%;visibility: visible;position: fixed;top: 150px;
}